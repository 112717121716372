<template>
    <div class="p-2">
        <div class="d-flex align-items-center mb-2">
            <b-breadcrumb class="mb-0 flex-fill" :items="breadcrumbs"></b-breadcrumb>
            <div class="flex-wrap">
                <router-link id="new-application" style="font-size: 14px;" class="btn btn-sm btn-outline-primary d-inline-block"
                             :to="{ name : 'applicationAdd'}">
                    <b-icon-plus></b-icon-plus>
                    New application
                </router-link>
            </div>
        </div>
        <div class="row no-gutters">
            <div class="col-3 pr-2">
                <b-card id="application-filters">
                    <filters-loader endpoint="application-filters" :current-filters="request" v-on:filters_updated="(filters) => { filtersUpdated(filters)}"/>
                </b-card>
            </div>
            <div class="col-9">
                    <paginated-result-loader endpoint="applications" cell-type="applications" :filters="request"></paginated-result-loader>
            </div>

        </div>
        <b-modal :no-close-on-backdrop="true" :hide-header=true :hide-footer=true v-model="showModal">
            <div class="text-right position-absolute" style="right: 10px; top:10px;">
                <a class="btn btn-sm text-primary" @click="() => showModal = false"><b-icon-x-circle></b-icon-x-circle></a>
            </div>
            <keep-alive include="applications">
                <router-view></router-view>
            </keep-alive>
        </b-modal>
    </div>
</template>

<script>
    import FiltersLoader from "../../components/FiltersLoader";
    import PaginatedResultLoader from "../../components/PaginatedResultLoader";
    import saveState from 'vue-save-state';

    export default {
        mixins: [saveState],
        name: "applications",
        components: {PaginatedResultLoader,FiltersLoader},
        data: function() {
            return {
                showModal : false,
                request : [],
                paginatedResponse : {},
                breadcrumbs : [
                    {
                        text: 'Dashboard',
                        to: {'name' : 'dashboard'}
                    },
                    {
                        text: 'Applications',
                        active: true
                    }
                ]
            }
        },
        created() {
            if(!Array.isArray(this.request)) {
                this.request = [];
            }
        },
        watch: {
            $route: {
                immediate: true,
                handler: function (newVal, oldVal) {
                    this.showModal = newVal.meta && newVal.meta.showModal;
                    if(newVal.params.data_updated !== undefined) {
                        this.request = _.clone(this.request)
                    }
                }
            },
            showModal: function (newVal, oldVal) {
                if (!newVal) {
                    if(this.$router.currentRoute.name !== 'applications') {
                        this.$router.replace({'name': 'applications'});
                    }
                }
                if(newVal !== oldVal && !newVal) {
                    this.request = _.clone(this.request)
                }
            }
        },
        methods : {
            filtersUpdated(filters) {
                this.request = filters;
            },
            getSaveStateConfig() {
                return {
                    'cacheKey': 'applicationsComponent',
                };
            },
        }

    }
</script>
